<!--
 * @description: 代码生成器自动生成
 * @author: liyubao | xufuxing
 * @version: 1.0
-->
<template>
  <div class="flex-column mzyorderss">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <el-input
          @keyup.enter.native="handleFilter"
          size="mini"
          style="width: 200px"
          class="filter-item"
          :placeholder="'名称'"
          v-model="listQuery.key"
        >
        </el-input>
        <el-button
          class="filter-item"
          size="mini"
          v-waves
          icon="el-icon-search"
          @click="handleFilter"
          >搜索</el-button
        >
        <permission-btn
          size="mini"
          v-on:btn-event="onBtnClicked"
        ></permission-btn>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <div class="bg-white" style="height: 100%">
        <el-table :data="list">
          <el-table-column label="项目名称" prop="order_id" align="center">
            <template slot-scope="scope">
              <i class="el-icon-folder-opened"></i> {{ scope.row.order_id }}
            </template>
          </el-table-column>
          <el-table-column
            prop="category_1_name"
            label="行业"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="category_2_name"
            label="管理范围"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="order_desc"
            label="管理体系"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="Datafilling(scope.row)"
                >数据填写</el-button
              >
              <!-- <el-button size="mini" @click="alert(scope.row)">打包导出</el-button> -->
            </template>
          </el-table-column>
          <el-table-column prop="creator" label="创建人" align="center">
          </el-table-column>
          <el-table-column 
            prop="create_time" 
            label="创建日期" 
            align="center" 
            sortable
          >
          </el-table-column>

          <el-table-column 
            prop="update_time" 
            label="修改日期" 
            align="center" 
            sortable
          >
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="handleCurrentChange"
        />
      </div>
      <el-dialog
        v-el-drag-dialog
        class="dialog-mini"
        width="500px"
        :title="textMap[dialogStatus]"
        :visible.sync="dialogFormVisible"
      >
        <el-form ref="form" :model="formData.order_id" label-width="100px">
          <el-form-item label="项目名称">
            <el-input
              placeholder="项目名称"
              v-model="formData.order_id"
            ></el-input>
          </el-form-item>
          <el-form-item label="行业">
            <el-select
              v-model="formData.category1"
              placeholder="请选择行业"
              @change="handleChange"
            >
              <el-option
                v-for="option in category1list1"
                :key="option.value"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理范围">
            <el-select
              v-model="formData.category2"
              placeholder="请选择管理范围"
              @change="handleChange2"
            >
              <el-option
                v-for="option in category1list2"
                :key="option.value"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理体系">
            <el-select
              v-model="formData.category3"
              placeholder="请选择管理体系"
            >
              <el-option
                v-for="option in category1list3"
                :key="option.value"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button size="mini" @click="dialogFormVisible = false"
            >取消</el-button
          >
          <el-button
            size="mini"
            v-if="dialogStatus == 'create'"
            type="primary"
            @click="createData"
            >确认</el-button
          >
        </div>
      </el-dialog>

      <el-dialog
        v-el-drag-dialog
        class="dialog-mini"
        width="70%"
        title="订单明细"
        :visible.sync="dialogdetailsvisible"
        @close="handleClose"
        :close-on-click-modal="false"
      >
        <el-table v-if="isshowtable" :data="order_details">
          <el-table-column
            prop="file_name"
            label="模板名称"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <i class="el-icon-document" v-if="scope.row.template_description"></i
              >{{ scope.row.template_description }}
            </template>
          </el-table-column>
          <el-table-column prop="template_name" label="文件名称" align="center">
          </el-table-column>
          <el-table-column prop="add_user" label="添加人" align="center">
          </el-table-column>
          <el-table-column prop="add_time" label="添加时间" align="center">
          </el-table-column>
          <!-- <el-table-column prop="business_form_xml" label="XML" align="center">
          </el-table-column> -->

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="Parsingform(scope.row)"
                >字段录入</el-button
              >
              <el-button size="mini" @click="alert(scope.row)">打包导出</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="form" v-if="isVisible && datalist.length">
          <el-form ref="form" label-width="150px">
            <el-form-item
              v-for="(item, index) in datalist"
              :label="item.name"
              :prop="item.name"
              :key="index"
            >
              <el-input v-model="item.value"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button @click="handleCancel">取消</el-button>
              <el-button @click="handleSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import * as mzyorder_details from "@/api/mzyorder_details";
import * as mzysystem_category2s from '@/api/mzysystem_category2s'
import * as mzyorderss from "@/api/mzyorderss";
import waves from "@/directive/waves"; // 水波纹指令
import Sticky from "@/components/Sticky";
import permissionBtn from "@/components/PermissionBtn";
import Pagination from "@/components/Pagination";
import elDragDialog from "@/directive/el-dragDialog";
import { defaultVal } from "@/utils/index";
import extend from "@/extensions/delRows.js";
import * as mzysystem_categorys from "@/api/mzysystem_categorys";
// import AuthForm from "../../components/Base/AuthForm.vue";
// import AuthTable from "../../components/Base/AuthTable.vue";
export default {
  name: "mzyorders",
  components: { Sticky, permissionBtn, Pagination },
  directives: {
    waves,
    elDragDialog,
  },
  mixins: [extend],
  data() {
    return {
      isVisible: true,
      isshowtable: true,
      datalist: {},
      SelectDetailsRow: {}, //明细被选中的行
      order_details: [], //明细数据
      category1list1: [],
      category1list2: [],
      category1list3: [],
      //表单内容
      formData: {
        order_id: "",
        category1: "",
        category2: "",
        category3: "",
      },
      headerList: [], // 主列表列定义
      multipleSelection: [], // 列表checkbox选中的值
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      dialogdetailsvisible: false,
      listQuery: {
        // 查询条件
        page: 1,
        limit: 20,
        key: undefined,
      },
      temp: {},
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "编辑",
        create: "添加",
      },
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
      },
    };
  },
  computed: {
    category3name: function () {
      var selectedOption = this.category1list3.find(
        (option) => option.id === this.formData.category3
      );
      return selectedOption.name;
    },
    category2name: function () {
      var selectedOption = this.category1list2.find(
        (option) => option.id === this.formData.category2
      );
      return selectedOption.name;
    },
    category1name: function () {
      var selectedOption = this.category1list1.find(
        (option) => option.id === this.formData.category1
      );
      return selectedOption.name;
    },
  },
  created() {
    this.getList();
    this.getListcategory();
  },
  methods: {
    handleClose() {
      this.datalist = [];
      this.isshowtable = true;
    },
    alert(row) {
      try {
        this.selectorder = row;
        // 假设你有一个服务层来处理与后台的交互，类似于以下形式
        /*const fileName = 'yourFileName.txt'; // 需要替换为实际的文件名
        const fileContent = new Uint8Array([ your file content here ]); // 文件内容需要传递到后台

        const response = await axios.post('/your-backend-api/generate-download-link', {
          fileName: fileName,
          fileContent: fileContent
        });

        if (response.data && response.data.downloadLink) {
          // 成功获取到下载链接，自动跳转到下载链接
          window.location.href = response.data.downloadLink;
        } else {
          console.error("未能获取到下载链接");
        }*/       

        // 发送请求到后端
        //axios.post('/api/export-file', {  })
        console.log(1);
        mzyorder_details.uploadFile(row.template_name)
          .then(response => {
            console.log('后端返回的完整响应:', response);
            
            // 直接从 response 对象中获取 obj
            const downloadUrl = response.obj; // 这里直接从 response 中获取 obj
            
            if (downloadUrl) {
              this.downloadUrl = downloadUrl;
              console.log('下载链接:', downloadUrl);
              // 跳转到下载链接
              window.location.href = downloadUrl;
            } else {
              alert('未找到相关文件');
            }
          })
          .catch(error => {
            console.error('导出文件时出错:', error);
            alert('导出文件失败');
          });



      } catch (error) {
        console.error("导出失败:", error);
      }
    },
    handleCancel() {
      //this.isVisible = false;
      location.reload();
    },
    handleSubmit() {
      // this.SelectDetailsRow.

      var arr = this.datalist;
      let xmlStr = "<root>";
      arr.forEach((item) => {
        xmlStr += `<${item.name}>${item.value}</${item.name}>`;
      });
      xmlStr += "</root>";
      console.log("xmlStr",xmlStr);
      console.log("SelectDetailsRow",this.SelectDetailsRow);
      // this.SelectDetailsRow.=xmlStr;

      this.SelectDetailsRow.review_user = xmlStr;
      mzyorder_details.add(this.SelectDetailsRow).then(() => {
        this.datalist = [];
        (this.SelectDetailsRow = {}), this.Datafilling(this.selectorder);
        this.isshowtable = true;
        this.$notify({
          title: "成功",
          message: "更新成功",
          type: "success",
          duration: 2000,
        });
      });
    },
    //解析表单
    Parsingform(row) {
      var temp = {
        page: 1,
        limit: 20,
        filename: row.template_name,
      };
      this.SelectDetailsRow = row;
      console.log("mc:",row.template_name);

      mzyorder_details.getFile(temp).then((data) => {
        this.datalist = data.obj;
        console.log("data222:",this.datalist);
        this.isshowtable = false;
      });
    },
    //分类数据获取
    handleChange() {
      console.log(12312);
      this.getListcategory(this.formData.category1, 1);
    },
    handleChange2() {
      console.log(212312);
      this.formData.category3 = '';  // 清空管理体系的选项
      this.getListcategory(this.formData.category2, 2);
    },
    getListcategory(id, level) {
      var listQuery = {
        page: 1,
        limit: 9999,
        key: undefined,
        parent_id: id,
      };
      if (!id) {
        mzysystem_categorys.DataDictionaryLoad(listQuery).then((response) => {
          this.category1list1 = response.data;
          console.log(response);
        });
        // this.headerList = response.columnFields;
        // this.total = response.count;
        // this.listLoading = false;
      } else {
        // 根据 level 来判断是获取管理范围还是管理体系的数据
        if (level === 1) {
          mzysystem_categorys.DataDictionaryByParent_id(listQuery).then((response) => {
            this.category1list2 = response.data;
            console.log('管理范围数据:', response);
          });
        } else if (level === 2) {
          mzysystem_categorys.DataDictionaryByParent_id2(listQuery).then((response) => {
            this.category1list3 = response.data;
            console.log('管理体系数据:', response);
          });
        }
      }
    },
    rowClick(row) {
      this.$refs.mainTable.clearSelection();
      this.$refs.mainTable.toggleRowSelection(row);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onBtnClicked: function (domId, callback) {
      console.log("you click:" + domId);
      switch (domId) {
        case "btnAdd":
          this.handleCreate();
          break;
        case "btnEdit":
          if (this.multipleSelection.length !== 1) {
            this.$message({
              message: "只能选中一个进行编辑",
              type: "error",
            });
            return;
          }
          this.handleUpdate(this.multipleSelection[0]);
          break;
        case "btnDel":
          if (this.multipleSelection.length < 1) {
            this.$message({
              message: "至少删除一个",
              type: "error",
            });
            return;
          }
          this.handleDelete(this.multipleSelection);
          break;
        case "btnExport":
          this.$refs.mainTable.exportExcel("资源文件", callback);
          break;
        default:
          break;
      }
    },
    getList() {
      this.listLoading = true;
      mzyorderss.getList(this.listQuery).then((response) => {
        this.list = response.data;
        response.columnFields.forEach((item) => {
          // 首字母小写
          item.columnName =
            item.columnName.substring(0, 1).toLowerCase() +
            item.columnName.substring(1);
        });
        this.headerList = response.columnFields;
        this.total = response.count;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page;
      this.listQuery.limit = val.limit;
      this.getList();
    },
    handleModifyStatus(row, disable) {
      // 模拟修改状态
      this.$message({
        message: "操作成功",
        type: "success",
      });
      row.disable = disable;
    },
    resetTemp() {
      let obj = {};
      this.headerList.forEach((item) => {
        obj[item.columnName] = defaultVal(item.entityType);
      });
      this.temp = Object.assign({}, obj); // copy obj
    },
    handleCreate() {
      // 弹出添加框
      this.resetTemp();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      // this.$nextTick(() => {
      //   this.$refs["dataForm"].clearValidate();
      // });
    },
    createData() {
      // 保存提交
      // this.$refs["dataForm"].validate(() => {
      //   mzyorderss.add(this.temp).then(() => {
      //     this.list.unshift(this.temp);
      //     this.dialogFormVisible = false;
      //     this.$notify({
      //       title: "成功",
      //       message: "创建成功",
      //       type: "success",
      //       duration: 2000,
      //     });
      //   });
      // });

      var temp = {        
        category_1_id: this.formData.category1,
        category_2_id: this.formData.category2,
        category_1_name: this.category1name,
        category_2_name: this.category2name,
        order_desc: this.category3name,
        order_id: this.formData.order_id,
      };

      mzyorderss.add(temp).then(() => {
        this.dialogFormVisible = false;
        this.$notify({
          title: "成功",
          message: "创建成功",
          type: "success",
          duration: 2000,
        });
        this.getList();
        this.formData = {
          order_id: "",
          category1: "",
          category2: "",
          category3: "",
        };
      });
    },
    handleUpdate(row) {
      // 弹出编辑框
      this.temp = Object.assign({}, row); // copy obj
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      // this.$nextTick(() => {
      //   this.$refs["dataForm"].clearValidate();
      // });
    },
    updateData() {
      // 更新提交
      this.$refs["dataForm"].validate(() => {
        const tempData = Object.assign({}, this.temp);
        mzyorderss.update(tempData).then(() => {
          for (const v of this.list) {
            if (v.id === this.temp.id) {
              const index = this.list.indexOf(v);
              this.list.splice(index, 1, this.temp);
              break;
            }
          }
          this.dialogFormVisible = false;
          this.$notify({
            title: "成功",
            message: "更新成功",
            type: "success",
            duration: 2000,
          });
        });
      });
    },
    Datafilling(rows) {
      this.selectorder = rows;
      //找对应明细
      var listQuery = {
        // 查询条件
        page: 1,
        limit: 9999,
        key: undefined,
        nams1: rows.order_desc,
        nams: rows.category_2_name
      };
      mzysystem_category2s.getdata(listQuery).then((response) => {
        //拿到明细数据
        this.order_details = response.data;
        console.log("data111",this.order_details);
        this.dialogdetailsvisible = true;
      });
    },
    handleDelete(rows) {
      // 多行删除
      this.delrows(mzyorderss, rows);
    },
  },
};
</script>
<style lang="scss">
.dialog-mini .el-select {
  width: 100%;
}

.mzyorderss {
  .el-form {
    overflow: hidden;
  }
  .el-table {
    font-size: 21px;
  }
}
</style>
